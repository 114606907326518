<template>
  <div>
    <div class="admin-table-search-container">
      <div class="admin-table-search">
        <el-form
          ref="queryForm"
          size="small"
          :model="tableQuery"
          @submit.native.prevent="search"
          inline
          :rules="rules"
        >
          <!-- <el-form-item label="角色名" prop="test">
            <tree-select
              :limit="0"
              v-model="tableQuery.test"
              :multiple="true"
              :options="options"
            />
          </el-form-item>
          <el-form-item label="角色名" prop="roleName">
            <el-input v-model="tableQuery.roleName" maxlength="50"></el-input>
          </el-form-item> -->
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              :loading="tableLoading"
              native-type="submit"
              class="admin-btn"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="admin-view-container">
      <div class="admin-methods-btns" style="text-align: right">
        <el-button size="small" @click="add(null, 1)" type="primary" plain
          >新增</el-button
        >
      </div>
      <div v-loading="tableLoading">
        <el-table :data="tableData.data" class="admin-table-list">
          <el-table-column prop="adminName" label="姓名"></el-table-column>
          <el-table-column prop="adminLoginName" label="账号"></el-table-column>
          <el-table-column
            prop="adminMobile"
            width="120"
            label="手机号"
          ></el-table-column>
          <el-table-column
            prop="adminSex"
            label="性别"
            :formatter="$formatter.dict($dict.sex)"
          ></el-table-column>
          <el-table-column
            prop="roleType"
            min-width="100"
            label="角色"
            :formatter="$formatter.dict($dict.roleType)"
          ></el-table-column>
          <el-table-column
            prop="addrName"
            min-width="140"
            label="已绑定地址"
          ></el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <admin-space class="admin-table-links">
                <el-link size="small" type="primary" @click="add(scope.row, 2)"
                  >编辑</el-link
                >
                <el-link
                  size="small"
                  type="warning"
                  @click="setRoots(scope.row)"
                  >绑定地址权限</el-link
                >
              </admin-space>
            </template>
          </el-table-column>
        </el-table>
        <div class="admin-pager-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tableQuery.page"
            :page-size="tableQuery.pageSize"
            :total="tableData.total"
            :layout="tableConfig.layout"
            background
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoleList } from "@/api/index";
export default {
  data() {
    return {
      rules: {
        roleName: [this.$rules.required],
        test: [this.$rules.required],
      },
      options: [
        {
          id: "aasdasdasdasdasdadasd",
          label: "a",
          children: [
            {
              id: "aa",
              label: "abasdfsad阿萨德fasfasdf阿萨德阿萨德",
            },
            {
              id: "ab",
              label: "ab",
            },
          ],
        },
        {
          id: "ba",
          label: "b",
        },
        {
          id: "cs",
          label: "c",
        },
        {
          id: "cd",
          label: "c",
        },
        {
          id: "cf",
          label: "c",
        },
        {
          id: "cg",
          label: "c",
        },
        {
          id: "ch",
          label: "c",
        },
        {
          id: "aac",
          label: "c",
        },
        {
          id: "css",
          label: "c",
        },
      ],
      tableQuery: {
        pageSize: 10,
        page: 1,
      },
      tableData: [],
      tableLoading: false,
      tableConfig: {
        layout: "total, sizes, prev, pager, next, jumper",
      },
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.tableLoading = true;
      getRoleList(this.tableQuery).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 200) {
          this.tableData.data = res.data.data.list.filter(
            (m) => m.roleType != 1
          );
          this.tableData.total = res.data.data.count;
        }
      });
    },
    async setRoots(row) {
      let vm = this;
      let vNode = this.$createElement((await import("./bind.vue")).default, {
        key: Math.random(),
        props: { row: row },
        on: {
          success() {
            vm.getTable();
          },
        },
      });
      this.$adminDialog(vNode, {
        title: "绑定管理员和地址",
        width: "530px",
      });
    },
    async add(row, type) {
      let vm = this;
      let vNode = this.$createElement((await import("./add.vue")).default, {
        key: Math.random(),
        props: { row: row },
        on: {
          success() {
            vm.getTable();
          },
        },
      });
      this.$adminDialog(vNode, {
        title: type == 1 ? "新增" : "编辑",
        width: "530px",
      });
    },
    // 每页数量切换
    handleSizeChange(val) {
      this.tableQuery.page = 1;
      this.tableQuery.pageSize = val;
      this.getTable();
    },
    // 页数切换
    handleCurrentChange(val) {
      this.tableQuery.page = val;
      this.getTable();
    },
  },
};
</script>

<style></style>
